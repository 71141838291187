exports.components = {
  "component---src-pages-2023-sponsors-js": () => import("./../../../src/pages/2023-sponsors.js" /* webpackChunkName: "component---src-pages-2023-sponsors-js" */),
  "component---src-pages-2024-sponsors-js": () => import("./../../../src/pages/2024-sponsors.js" /* webpackChunkName: "component---src-pages-2024-sponsors-js" */),
  "component---src-pages-2025-sponsors-js": () => import("./../../../src/pages/2025-sponsors.js" /* webpackChunkName: "component---src-pages-2025-sponsors-js" */),
  "component---src-pages-2025-sponsorship-js": () => import("./../../../src/pages/2025-sponsorship.js" /* webpackChunkName: "component---src-pages-2025-sponsorship-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agenda-js": () => import("./../../../src/pages/agenda.js" /* webpackChunkName: "component---src-pages-agenda-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-success-js": () => import("./../../../src/pages/contact-success.js" /* webpackChunkName: "component---src-pages-contact-success-js" */),
  "component---src-pages-golf-js": () => import("./../../../src/pages/golf.js" /* webpackChunkName: "component---src-pages-golf-js" */),
  "component---src-pages-hotel-js": () => import("./../../../src/pages/hotel.js" /* webpackChunkName: "component---src-pages-hotel-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

